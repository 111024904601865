import { useCallback, useRef } from 'react';

// Utility function to open IndexedDB
const openDB = (
	dbName: string,
	version: number,
	deafultStoreName: string
): Promise<IDBDatabase> => {
	return new Promise((resolve, reject) => {
		const request = indexedDB.open(dbName, version);

		request.onupgradeneeded = () => {
			try {
				const db = request.result;
				if (!db.objectStoreNames.contains(deafultStoreName)) {
					db.createObjectStore(deafultStoreName);
				}
			} catch (error) {
				reject(error);
			}
		};

		request.onsuccess = () => resolve(request.result);
		request.onerror = () => {
			reject(request.error);
		};

		request.onblocked = () => {
			reject(new Error('IndexedDB is blocked.'));
		};
	});
};

// Utility function to perform database operations
const useIndexedDB = () => {
	const db = useRef<IDBDatabase | null>(null);

	const initIndexedDB = useCallback(
		async (dbName: string, version: number, deafultStoreName: string) => {
			await openDB(dbName, version, deafultStoreName)
				.then(database => {
					db.current = database;
				})
				.catch(error => {
					// eslint-disable-next-line no-console
					console.log('Error initializing IndexedDB:', error);
				});

			// return () => {
			//     isMounted = false;
			//     if (db) db.close();
			// };
		},
		[]
	);

	const performTransaction = useCallback(
		async (
			storeName: string,
			mode: IDBTransactionMode,
			operation: (store: IDBObjectStore) => IDBRequest
		): Promise<any> => {
			return new Promise((resolve, reject) => {
				if (!db.current) {
					reject(new Error('Database not initialized'));
					return;
				}

				try {
					const transaction = db.current.transaction(storeName, mode);

					transaction.oncomplete = () => resolve(undefined);
					transaction.onerror = () => {
						reject(transaction.error);
					};
					transaction.onabort = () => {
						reject(transaction.error);
					};

					const store = transaction.objectStore(storeName);
					const request = operation(store);

					request.onsuccess = () => resolve(request.result);
					request.onerror = () => {
						reject(request.error);
					};
				} catch (error) {
					reject(error);
				}
			});
		},
		[db]
	);

	const addIDbItem = useCallback(
		async (storeName: string, item: Record<string, any>, key?: IDBValidKey) => {
			try {
				await performTransaction(storeName, 'readwrite', store =>
					store.put(item, key)
				);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('Error adding item:', error);
				throw error;
			}
		},
		[performTransaction]
	);

	const getIDbItem = useCallback(
		async (storeName: string, id: string | number) => {
			try {
				return await performTransaction(storeName, 'readonly', store =>
					store.get(id)
				);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('Error retrieving item:', error);
				throw error;
			}
		},
		[performTransaction]
	);

	const getAllIDbItems = useCallback(
		async (storeName: string) => {
			try {
				return await performTransaction(storeName, 'readonly', store =>
					store.getAll()
				);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('Error retrieving all items:', error);
				throw error;
			}
		},
		[performTransaction]
	);

	const deleteIDbItem = useCallback(
		async (storeName: string, id: string | number) => {
			try {
				await performTransaction(storeName, 'readwrite', store =>
					store.delete(id)
				);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('Error deleting item:', error);
				throw error;
			}
		},
		[performTransaction]
	);

	const clearIDbStore = useCallback(
		async (storeName: string) => {
			try {
				await performTransaction(storeName, 'readwrite', store =>
					store.clear()
				);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('Error clearing store:', error);
				throw error;
			}
		},
		[performTransaction]
	);

	return {
		addIDbItem,
		getIDbItem,
		getAllIDbItems,
		deleteIDbItem,
		clearIDbStore,
		initIndexedDB,
	};
};

export default useIndexedDB;
