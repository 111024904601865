import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Button, Loader } from '@storybook';
import { REACT_APP_FINIX_ENV_TYPE as FINIX_ENV_TYPE } from 'envs';
import { useNextStep, useNotification } from 'hooks';
import { useFundInvestmentRequests } from 'views/fund-investment/stores';
import {
	TokenizationResponse,
	PaymentPayload,
	FormState,
	BinInformation,
} from './sdk-types';
import { SessionDetailsState } from 'hooks/use-next-step/stores';
import { useScriptLoaderWithRetry } from './retry-sdk-script';
import { FINIX_SCRIPT } from './constant';

declare global {
	interface Window {
		Finix: {
			getSessionKey: () => string | null;
			Auth: (
				environment: 'sandbox' | 'production' | 'live',
				applicationId: string,
				callback: (sessionKey: string) => void
			) => void;
			CardTokenForm: (
				elementId: string,
				options: any
			) => {
				submit: (
					environment: string,
					applicationId: string,
					callback: (
						err: Error | null,
						res: TokenizationResponse | null
					) => void
				) => void;
			};
		};
		Auth: (
			environment: 'sandbox' | 'production' | 'live',
			applicationId: string,
			callback: (sessionKey: string) => void
		) => void;
	}
}

type IEnvType = 'sandbox' | 'production' | 'live';

interface IProps {
	setCardDetailsShow: (value: boolean) => void;
	handleBreakSenrio: () => void;
}

export const FundPaySDK: FC<IProps> = ({
	setCardDetailsShow,
	handleBreakSenrio,
}) => {
	const { errorNotification } = useNotification();
	const { submitPayment } = useFundInvestmentRequests();
	const { sessionPayloadDetail, handleNext } = useNextStep();
	const [loading, setLoading] = useState(false);
	const [formLoaded, setFormLoaded] = useState(true);
	const [form, setForm] = useState<ReturnType<
		typeof window.Finix.CardTokenForm
	> | null>(null);
	const [formHasErrors, setFormHasErrors] = useState(true);
	const sessionDetails = useRecoilValue(SessionDetailsState);
	const [sessionKey, setSessionKey] = useState<string>('');

	const { reloadFinixScript, isScriptLoaded } = useScriptLoaderWithRetry(
		FINIX_SCRIPT,
		useCallback(() => {
			setCardDetailsShow(false);
			handleBreakSenrio();
		}, [setCardDetailsShow, handleBreakSenrio])
	);

	const onFailed = useCallback(
		(res: { statusCode: number; message: string }) => {
			const errorMessage = res?.message || 'Payment failed, please try again!';
			errorNotification(errorMessage);
			setLoading(false);
		},
		[errorNotification]
	);
	const { nodes } = sessionDetails ?? {};
	const { paymentProviders } = nodes ?? {};

	const getApplicationKey = useMemo(() => {
		const finixData = (paymentProviders ?? []).find(
			(item: { providerName: string }) => item?.providerName === 'FINIX'
		);
		return finixData?.details?.applicationId ?? '';
	}, [paymentProviders]);

	const handlePayment = useCallback(
		async (tokenId: string) => {
			const payload: PaymentPayload = {
				tokenId,
				sessionId: sessionPayloadDetail.sessionId,
				paymentMethod: 'CREDIT_CARD',
				paymentSessionKey: sessionKey,
			};
			await submitPayment(payload, handleNext, onFailed);
		},
		[
			sessionPayloadDetail?.sessionId,
			sessionKey,
			submitPayment,
			handleNext,
			onFailed,
		]
	);

	const handleSubmit = useCallback(() => {
		if (!form) return;
		setLoading(true);
		form.submit(
			FINIX_ENV_TYPE,
			getApplicationKey ?? '',
			function (err: Error | null, res: TokenizationResponse | null) {
				if (err) {
					errorNotification('Tokenization failed. Please try again.');
					setLoading(false);
				} else if (res) {
					const token = res.data.id;
					handlePayment(token);
				}
			}
		);
	}, [form, getApplicationKey, errorNotification, handlePayment]);

	useEffect(() => {
		const finixData = (paymentProviders ?? []).find(
			(item: { providerName: string }) => item?.providerName === 'FINIX'
		);

		const merchantId = finixData?.details?.merchantId ?? '';

		if (window?.Finix?.Auth && merchantId) {
			window?.Finix?.Auth(
				FINIX_ENV_TYPE as IEnvType,
				merchantId,
				(newSessionKey: string) => {
					// eslint-disable-next-line no-console
					console.log('Generated Session Key:', newSessionKey);
					setSessionKey(newSessionKey);
				}
			);
		} else {
			// eslint-disable-next-line no-console
			console.error(
				'Finix.Auth is unavailable or no applicationId is provided.'
			);
		}
	}, [paymentProviders]);

	useEffect(() => {
		if (typeof window.Finix === 'undefined') {
			reloadFinixScript?.();
			return;
		}
		// Check if CardTokenForm is a valid function
		if (typeof window?.Finix?.CardTokenForm !== 'function') {
			reloadFinixScript?.();
			return;
		}
		setFormLoaded(true);
		const finixForm = window?.Finix?.CardTokenForm('finix-form', {
			showAddress: false,
			success: {
				color: '#5cb85c',
			},
			errorMessages: {
				name: 'Please enter a valid name',
				expiration_date: 'Please enter a valid expiration date',
				security_code: 'Please enter a valid CVV',
				number: 'Please enter a valid card numbers',
			},
			styles: {
				default: {
					color: '#000',
					border: '1px solid #d7dff5',
					borderRadius: '4px',
					padding: '8px 16px',
					height: '44px',
					fontSize: '16px',
					background: '#f5f8ff',
					marginBottom: '8px',
				},
			},
			onUpdate: function (
				_state: FormState,
				_binInformation: BinInformation,
				formHasErrors: boolean
			) {
				setFormHasErrors(formHasErrors);
			},
			onLoad: function () {
				setFormLoaded(false);
			},
		});

		setForm(finixForm);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isScriptLoaded]);

	const renderLoader = useMemo(() => {
		return (
			formLoaded && (
				<div className="sdk-screen__loader">
					<Loader dimension={50} type="loader" />
				</div>
			)
		);
	}, [formLoaded]);

	const isPaybuttonDisable = useMemo(() => {
		return formHasErrors;
	}, [formHasErrors]);

	const isbackButtonDisable = useMemo(() => {
		return loading;
	}, [loading]);

	return (
		<Fragment>
			<button
				className="sdk-screen__back-button"
				disabled={isbackButtonDisable}
				onClick={() => setCardDetailsShow(false)}
			>
				<i className="ri-arrow-left-line"></i> Back
			</button>
			{!formLoaded && (
				<div className="fi-connect-bank__header__title sdk-screen__heading">
					Enter your card details
				</div>
			)}
			<div className={'sdk-screen__wrapper'}>
				{renderLoader}
				<div className="sdk-screen__form" id="finix-form">
					{/* Finix SDK will attach the form here */}
				</div>
			</div>
			<Button
				label={loading ? <Loader type="loader" dimension={24} /> : 'Pay'}
				handleClick={handleSubmit}
				type="button button__filled button__filled--primary button__large button__block"
				disabled={isPaybuttonDisable || loading}
			/>
		</Fragment>
	);
};
